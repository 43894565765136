import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useAdminAuth from "../../../hooks/zustand/admin/useAdminAuth";
import useLogStore from "../../../hooks/zustand/admin-log/useLogStore";
import { useNavigate } from "react-router-dom";
// components
import {
  FormProvider,
  RHFInput,
  RHFInputAddon,
} from "../../../components/RHForm";
import ButtonLp from "../../../components/ButtonLp";
import ModalAccountLocked from "../Modal/Modal.account-locked";
import { enqueueSnackbar } from "notistack";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import useHomeSlider from '../../../hooks/zustand/useHomeSlider';

const schemaUseId = yup.object().shape({
  adminId: yup.string().required("Admin ID is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters"),
});

const defaultValues = {
  adminId: "",
  password: "",
};

const SignInAdmin = () => {
  const logStore = useLogStore();
  const navigate = useNavigate();
  const auth = useAdminAuth();
  const homeSliderStore = useHomeSlider();
  const [bgImg, setBgImg ] = useState(process.env.REACT_APP_LOGIN_BG_IMG);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(schemaUseId),
    defaultValues,
  });

  const initialGetBgImg =  async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? '' !== '')
        setBgImg(resp.data.slider.bg_img)
    }
  }
  
  useEffect(() => {
    // const handleBeforeUnload = ( event ) => {
    //   event.preventDefault();
     
    // };

    // window.addEventListener('beforeunload', handleBeforeUnload);
    initialGetBgImg();
    i18next.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
    const disableBack =() => { window.history.forward(); }
    disableBack();
    window.addEventListener('onunload', disableBack);
    return () => {
      window.removeEventListener('beforeunload', disableBack);
    };
  }, []);

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const res = await auth.login(data);
    if (res?.token) {
      navigate("/en/dashboard/admin/user-list");
      await auth.getMe();
      await logStore.createData({
        adminId: data.adminId,
        memberId: '',
        log: `${data.adminId} login successfully`,
        payload: data, 
        action: 'login',
        module: 'Admin',
        table: 'admins'
      });
      reset();
    } else {
      enqueueSnackbar("An error occured", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <>
      <div
      className="w-full h-screen relative"
      style={{
        backgroundImage: `url('${bgImg}')`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        opacity: 0.5
      }}
    >
    
    </div>
    <div className="w-full h-screen px-5 py-10 flex justify-center items-center top-0 left-0 absolute z-10">
        <div className="w-full lg:w-[30rem] bg-white overflow-hidden rounded-lg shadow-lg p-5 lg:px-10 py-10 lg:py-14 mx-auto">
          <div className="flex flex-col items-center">
            <img
            style={{ 
              width: '50px'
              }}
              src={process.env.REACT_APP_LOGO_PATH}
              //src="images/Produk-Kewangan-Black-Logo.png"
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-36 lg:w-40 object-contain object-center"
            />
            <div className="h-12"></div>
          </div>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 lg:gap-8 w-full mx-auto">
              <div>
                <div className="flex justify-between items-center mb-2">
                  <label className="text-sm font-medium text-gray-900">
                    Admin ID
                  </label>
                </div>
                <RHFInputAddon name="adminId" addon="ID" />
              </div>

              <div>
                <div className="flex justify-between items-center mb-2">
                  <label className="text-sm font-medium text-gray-900">
                    Password
                  </label>
                </div>
                <RHFInput name="password" type="password" />
              </div>
              <ButtonLp
                type="submit"
                className="w-40 mx-auto mt-5"
                loading={isSubmitting}
              >
                Sign In
              </ButtonLp>
            </div>
          </FormProvider>
          <div className="mt-4 text-center">
            <Link to="/" className="font-semibold text-blue-500 text-sm">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
      <ModalAccountLocked open={open} close={() => setOpen(false)} />
    </>
    
  );
};

export default SignInAdmin;
